<template>
  <section
    class="box-height similarResume content-container"
    style="padding-bottom: 20px; max-height: calc(100vh - 70px); overflow: auto"
  >
    <div style="background-color: #fff">
      <el-form
        style="margin: 0"
        @submit.native.prevent
        :model="searchInfo"
        ref="filter"
      >
        <el-row
          :gutter="24"
          style="width: 100%; margin: 0; background-color: #fff"
          class="searchBox"
        >
          <el-col
            class="searchPanel1"
            style="
              padding-left: 0;
              padding-right: 0;
              background: #fafafa;
              border-top: 1px #eee solid;
              border-bottom: 1px #eee solid;
            "
          >
            <el-form
              label-width="110px"
              :model="searchInfo"
              ref="searchInfo"
              class="searchInfo"
              :rules="rulesOfSearch"
            >
              <el-col :span="24" style="padding-left: 0">
                <el-col :span="24" style="padding: 0">
                  <el-form-item
                    label="现居住城市："
                    prop="nowLocations"
                    style="margin: 10px 0 0; height: 32px"
                  >
                    <city-choose
                      v-model="searchInfo.nowLocations"
                      @mainSearch="
                        submitForm('现居住城市', searchInfo.nowLocations)
                      "
                    ></city-choose>
                    <el-popover
                      placement="bottom"
                      trigger="click"
                      popper-class="moreCity"
                    >
                      <el-cascader
                        :key="cascaderCityKey"
                        filterable
                        clearable
                        size="mini"
                        style="width: 260px; height: 34px"
                        @change="handleChangeNowLocations($event)"
                        v-model="searchInfo.nowLocations"
                        :show-all-levels="false"
                        :options="ColumnCityList"
                        collapse-tags
                        :props="cityProps"
                      ></el-cascader>
                      <span
                        style="margin-left: 15px"
                        slot="reference"
                        class="hoverColorChange"
                        @click="(isMoreCity = !isMoreCity), isMoreCityClk()"
                        >更多
                        <i
                          :class="{
                            'el-icon-arrow-down': !isMoreCity,
                            'el-icon-arrow-up': isMoreCity,
                          }"
                        ></i
                      ></span>
                    </el-popover>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col style="padding-left: 0">
                <el-form-item
                  label="工作年限："
                  prop="workExperiences"
                  style="margin-bottom: 0; height: 32px"
                >
                  <el-checkbox-group
                    class="radioGroupBtn"
                    size="mini"
                    v-model="searchInfo.workExperiences"
                    @change="workExperienceChanged($event)"
                    text-color="#e60012"
                  >
                    <el-checkbox-button label="UnLimit"
                      >不限</el-checkbox-button
                    >
                    <el-checkbox-button label="OneToThree"
                      >1-3年</el-checkbox-button
                    >
                    <el-checkbox-button label="ThreeToFive"
                      >3-5年</el-checkbox-button
                    >
                    <el-checkbox-button label="FiveToTen"
                      >5-10年</el-checkbox-button
                    >
                    <el-checkbox-button label="TenMore"
                      >10年以上</el-checkbox-button
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col style="padding-left: 0; margin-bottom: 10px" :span="20">
                <el-form-item
                  label="教育经历："
                  prop="educations"
                  style="margin: 0; height: 32px"
                >
                  <el-checkbox-group
                    class="radioGroupBtn"
                    size="mini"
                    v-model="searchInfo.educations"
                    @change="educationsChanged($event)"
                    text-color="#e60012"
                  >
                    <el-checkbox-button label="UnLimit"
                      >不限</el-checkbox-button
                    >
                    <el-checkbox-button label="本科及以上"></el-checkbox-button>
                    <el-checkbox-button label="硕士及以上"></el-checkbox-button>
                    <el-checkbox-button label="博士及以上"></el-checkbox-button>
                    <el-checkbox-button label="大专及以上"></el-checkbox-button>
                    <el-checkbox-button label="高中及以下"></el-checkbox-button>
                  </el-checkbox-group>
                  <el-checkbox-group
                    class="radioGroupBtn"
                    size="mini"
                    v-model="searchInfo.checkSchoolType"
                    @change="educationsChanged($event, 'checkSchoolType')"
                    text-color="#e60012"
                  >
                    <el-checkbox-button label="985/211"></el-checkbox-button>
                  </el-checkbox-group>
                  <el-checkbox-group
                    class="radioGroupBtn"
                    size="mini"
                    v-model="searchInfo.is_tongzhao"
                    @change="educationsChanged($event, 'is_tongzhao')"
                    text-color="#e60012"
                  >
                    <el-checkbox-button label="统招"></el-checkbox-button>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>

              <el-col
                :span="24"
                style="padding: 0 0 20px 0; width: calc(100% - 24px)"
              >
                <el-col :span="8" class="labelAppend">
                  <el-form-item label="姓名：" prop="userName">
                    <el-input
                      type="text"
                      @focus="isShowName = true"
                      @blur="blurUser($event)"
                      style="min-width: 180px; border-radius: 5px"
                      v-model.trim="searchInfo.userName"
                      placeholder="支持模糊搜索"
                      @keydown.enter.native="
                        submitForm('姓名', searchInfo.userName, 'enter')
                      "
                    >
                      <div
                        v-show="isShowName"
                        class="changeButtonStyle"
                        slot="append"
                      >
                        <el-button
                          icon="el-icon-search"
                          @click="submitForm('姓名', searchInfo.userName)"
                        ></el-button>
                      </div>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="labelAppend">
                  <el-form-item label="联系方式：" prop="contact">
                    <el-input
                      v-model.trim="searchInfo.contact"
                      @focus="isShowContact = true"
                      @blur="blurContact($event)"
                      @keydown.enter.native="
                        submitForm('联系方式', searchInfo.contact, 'enter')
                      "
                    >
                      <div
                        v-show="isShowContact"
                        class="changeButtonStyle"
                        slot="append"
                      >
                        <el-button
                          icon="el-icon-search"
                          @click="submitForm('联系方式', searchInfo.contact)"
                        ></el-button>
                      </div>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="行业：" prop="vocationIds">
                    <el-cascader
                      :key="isResouceShow"
                      style="width: 100%; height: 34px; min-width: 180px"
                      clearable
                      size="mini"
                      filterable
                      :show-all-levels="false"
                      :options="ColumnInList"
                      collapse-tags
                      v-model="searchInfo.vocationIds"
                      @change="submitForm('行业', searchInfo.vocationIds)"
                      :props="props"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="8" style="height: 49px">
                  <el-form-item label="职能：" prop="functionIds">
                    <el-cascader
                      :key="isResouceShow"
                      style="width: 100%; min-width: 180px"
                      clearable
                      @change="submitForm('职能', searchInfo.functionIds)"
                      size="mini"
                      filterable
                      :show-all-levels="false"
                      :options="ColumnPosList"
                      collapse-tags
                      v-model="searchInfo.functionIds"
                      :props="props"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="职位名称：" prop="title">
                    <el-input
                      @focus="isShowTitle = true"
                      @blur="blurTitle($event)"
                      class="input-with-select appandSelect"
                      v-model.trim="searchInfo.title"
                      style="width: calc(100%); float: left; position: relative"
                      placeholder="支持模糊搜索"
                      @keydown.enter.native="
                        submitForm('职位名称', searchInfo.title, 'enter')
                      "
                    >
                      <el-select
                        v-model="searchInfo.isAllTitle"
                        slot="prepend"
                        placeholder="请选择"
                      >
                        <el-option label="当前职位" :value="false"></el-option>
                        <el-option label="全部职位" :value="true"></el-option>
                      </el-select>
                    </el-input>
                    <el-button
                      v-show="isShowTitle"
                      style="
                        padding: 9px;
                        background: #ff3f3f;
                        color: white;
                        position: absolute;
                        right: 0;
                        top: -1px;
                      "
                      icon="el-icon-search"
                      @click="submitForm('职位名称', searchInfo.title)"
                    ></el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="公司名称：" prop="company">
                    <el-input
                      @focus="isShowCompany = true"
                      @blur="blurCompany($event)"
                      class="input-with-select appandSelect"
                      v-model="searchInfo.company"
                      style="width: calc(100%); float: left; position: relative"
                      placeholder="支持模糊搜索"
                      @keydown.enter.native="
                        submitForm('公司名称', searchInfo.company, 'enter')
                      "
                    >
                      <el-select
                        v-model="searchInfo.isAllCompany"
                        slot="prepend"
                        placeholder="请选择"
                      >
                        <el-option label="当前公司" :value="false"></el-option>
                        <el-option label="全部公司" :value="true"></el-option>
                      </el-select>
                    </el-input>
                    <el-button
                      v-show="isShowCompany"
                      style="
                        padding: 9px;
                        background: #ff3f3f;
                        color: white;
                        position: absolute;
                        right: 0;
                        top: -1px;
                      "
                      icon="el-icon-search"
                      @click="submitForm('公司名称', searchInfo.company)"
                    ></el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="年龄："
                    class="delFocus"
                    style="min-width: 280px; margin-bottom: 0 !important"
                  >
                    <el-form-item
                      label-width="0"
                      prop="minAge"
                      style="
                        width: 46%;
                        display: inline-block;
                        margin-bottom: 0;
                      "
                    >
                      <el-input
                        class="appendInput"
                        v-model.trim.number="searchInfo.minAge"
                        @keyup.enter.native="
                          submitForm('年龄', searchInfo.minAge, 'enter')
                        "
                        ><span slot="suffix">岁</span></el-input
                      >
                    </el-form-item>
                    <span
                      style="
                        display: inline-block;
                        height: 35px;
                        line-height: 35px;
                        width: 8%;
                        text-align: center;
                      "
                    >
                      -
                    </span>
                    <el-form-item
                      label-width="0"
                      prop="maxAge"
                      style="
                        width: 46%;
                        display: inline-block;
                        margin-bottom: 0;
                      "
                    >
                      <el-input
                        class="appendInput"
                        v-model.trim.number="searchInfo.maxAge"
                        @keyup.enter.native="
                          submitForm('年龄', searchInfo.maxAge, 'enter')
                        "
                        ><span slot="suffix">岁</span></el-input
                      >
                    </el-form-item>
                  </el-form-item>
                </el-col>
                <div style="padding: 10px 0; padding-right: 35px; float: right">
                  <span
                    @click="isMoreItemShow = !isMoreItemShow"
                    class="showMore hoverColorChange"
                    >{{ isMoreItemShow ? "收起" : "展开" }}更多条件<i
                      :class="{
                        'el-icon-arrow-up': isMoreItemShow,
                        'el-icon-arrow-down': !isMoreItemShow,
                      }"
                      style="padding-left: 5px"
                    ></i
                  ></span>
                </div>
              </el-col>

              <el-collapse-transition>
                <div v-if="isMoreItemShow">
                  <el-col
                    class="transition-box"
                    style="
                      padding: 15px 0 0;
                      border-top: 1px solid #ededed;
                      width: calc(100% - 24px);
                    "
                  >
                    <el-col
                      :span="24"
                      style="padding-left: 0; padding-right: 0"
                    >
                      <el-col :span="8">
                        <el-form-item
                          label="候选人类型："
                          prop="resumeType"
                          style="min-width: 280px"
                        >
                          <el-radio
                            class="radioBtn"
                            @click.native.prevent="radioClick(1)"
                            style="margin-right: 10px"
                            v-model="searchInfo.resumeType"
                            :label="1"
                            >候选人</el-radio
                          >
                          <el-radio
                            class="radioBtn"
                            @click.native.prevent="radioClick(2)"
                            v-model="searchInfo.resumeType"
                            :label="2"
                            >coldcall</el-radio
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="性别："
                          prop="sex"
                          style="min-width: 280px"
                        >
                          <el-radio
                            class="radioBtn"
                            @click.native.prevent="radioClick('男')"
                            style="margin-right: 38px"
                            v-model="searchInfo.sex"
                            label="男"
                          ></el-radio>
                          <el-radio
                            class="radioBtn"
                            @click.native.prevent="radioClick('女')"
                            v-model="searchInfo.sex"
                            label="女"
                          ></el-radio>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="第一学历："
                          prop="firstDegrees"
                          style="min-width: 280px"
                        >
                          <el-select
                            multiple
                            collapse-tags
                            clearable
                            v-model="searchInfo.firstDegrees"
                            style="width: 100%"
                            @change="
                              submitForm('第一学历', searchInfo.firstDegrees)
                            "
                          >
                            <el-option label="初中" value="初中"></el-option>
                            <el-option label="高中" value="高中"></el-option>
                            <el-option label="中技" value="中技"></el-option>
                            <el-option label="中专" value="中专"></el-option>
                            <el-option label="大专" value="大专"></el-option>
                            <el-option label="本科" value="本科"></el-option>
                            <el-option label="硕士" value="硕士"></el-option>
                            <el-option label="博士" value="博士"></el-option>
                            <el-option
                              label="博士后"
                              value="博士后"
                            ></el-option>
                            <el-option label="MBA" value="MBA"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="求职状态："
                          prop="jobStatus"
                          style="min-width: 280px"
                        >
                          <el-select
                            multiple
                            collapse-tags
                            clearable
                            v-model="searchInfo.jobStatus"
                            @change="
                              submitForm('求职状态', searchInfo.jobStatus)
                            "
                            style="width: 100%"
                          >
                            <el-option
                              label="在职看机会"
                              value="在职看机会"
                            ></el-option>
                            <el-option
                              label="在职不看机会"
                              value="在职不看机会"
                            ></el-option>
                            <el-option label="离职" value="离职"></el-option>
                            <el-option
                              label="应届毕业生"
                              value="应届毕业生"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="期望行业："
                          prop="forwardVocations"
                          style="min-width: 280px"
                        >
                          <el-cascader
                            :key="isResouceShow"
                            clearable
                            size="mini"
                            filterable
                            :show-all-levels="false"
                            :options="ColumnInList"
                            collapse-tags
                            @change="
                              submitForm(
                                '期望行业',
                                searchInfo.forwardVocations
                              )
                            "
                            v-model="searchInfo.forwardVocations"
                            :props="props"
                            style="width: 100%; height: 34px"
                          ></el-cascader>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="期望城市："
                          prop="forwardLocations"
                          style="min-width: 280px"
                        >
                          <el-cascader
                            :key="isResouceShow"
                            filterable
                            clearable
                            size="mini"
                            :show-all-levels="false"
                            @change="
                              submitForm(
                                '期望城市',
                                searchInfo.forwardLocations
                              )
                            "
                            style="width: 100%; height: 34px"
                            :options="ColumnCityList"
                            collapse-tags
                            v-model="searchInfo.forwardLocations"
                            :props="cityProps"
                          ></el-cascader>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8" class="labelAppend1">
                        <el-form-item
                          label="期望职位："
                          prop="forwardTitle"
                          style="min-width: 280px"
                        >
                          <el-input
                            v-model.trim="searchInfo.forwardTitle"
                            @focus="isShowExpectPosition = true"
                            @blur="blurExpectPosition($event)"
                            @keydown.enter.native="
                              submitForm(
                                '期望职位',
                                searchInfo.forwardTitle,
                                'enter'
                              )
                            "
                            placeholder="支持模糊搜索"
                          >
                            <div
                              v-show="isShowExpectPosition"
                              class="changeButtonStyle"
                              slot="append"
                            >
                              <el-button
                                style="
                                  border-radius: 0;
                                  padding: 9px;
                                  background: #ff3f3f;
                                  color: white;
                                "
                                icon="el-icon-search"
                                @click="
                                  submitForm(
                                    '期望职位',
                                    searchInfo.forwardTitle,
                                    'enter'
                                  )
                                "
                              ></el-button>
                            </div>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" style="height: 50px">
                        <el-form-item
                          label="期望月薪："
                          class="delFocus"
                          style="min-width: 280px"
                        >
                          <el-form-item
                            label-width="0"
                            prop="lowAimSalary"
                            style="
                              width: 47%;
                              display: inline-block;
                              margin-bottom: 0;
                            "
                          >
                            <el-input
                              class="appendInput"
                              v-model.number.trim="searchInfo.lowAimSalary"
                              @change="submitForm()"
                              @keydown.enter.native="
                                submitForm(
                                  '期望月薪',
                                  searchInfo.lowAimSalary,
                                  'enter'
                                )
                              "
                              ><span slot="suffix" class="appendInput"
                                >元</span
                              ></el-input
                            >
                          </el-form-item>
                          <span
                            style="
                              display: inline-block;
                              height: 35px;
                              line-height: 35px;
                              width: 6%;
                              text-align: center;
                            "
                          >
                            -
                          </span>
                          <el-form-item
                            label-width="0"
                            prop="highAimSalary"
                            style="
                              width: 47%;
                              display: inline-block;
                              margin-bottom: 0;
                            "
                          >
                            <el-input
                              class="appendInput"
                              v-model.number.trim="searchInfo.highAimSalary"
                              @change="submitForm()"
                              @keydown.enter.native="
                                submitForm(
                                  '期望月薪',
                                  searchInfo.highAimSalary,
                                  'enter'
                                )
                              "
                              ><span slot="suffix">元</span></el-input
                            >
                          </el-form-item>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" class="labelAppend1">
                        <el-form-item
                          label="简历编号："
                          prop="id"
                          style="min-width: 280px"
                        >
                          <el-input
                            v-model.trim="searchInfo.id"
                            @focus="isShowId = true"
                            @blur="blurId($event)"
                            @keydown.enter.native="
                              submitForm('简历编号', searchInfo.id, 'enter')
                            "
                          >
                            <div
                              v-show="isShowId"
                              class="changeButtonStyle"
                              slot="append"
                            >
                              <el-button
                                style="
                                  border-radius: 0;
                                  padding: 9px;
                                  background: #ff3f3f;
                                  color: white;
                                "
                                icon="el-icon-search"
                                @click="
                                  submitForm('简历编号', searchInfo.id, 'enter')
                                "
                              ></el-button>
                            </div>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" style="height: 50px">
                        <el-form-item label="人才拥有者：" prop="ownerUserIds">
                          <el-select
                            filterable
                            style="width: 100%"
                            clearable
                            v-model="searchInfo.ownerUserIds"
                            placeholder="请选择"
                            multiple
                            size="mini"
                            collapse-tags
                            @change="
                              submitForm('人才拥有者', searchInfo.ownerUserIds)
                            "
                            popper-class="selectManager"
                          >
                            <el-option
                              v-for="i in searchUserList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="渠道："
                          prop="channels"
                          style="min-width: 280px"
                        >
                          <el-select
                            multiple
                            collapse-tags
                            clearable
                            v-model="searchInfo.channels"
                            @change="submitForm('渠道', searchInfo.channels)"
                            style="width: 100%"
                          >
                            <el-option
                              v-for="(i, index) in channelList"
                              :key="'cha' + index"
                              :label="i.value"
                              :value="i.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="候选人标签："
                          prop="remark"
                          style="min-width: 280px"
                        >
                          <el-select
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            @change="
                              submitForm('候选人标签', searchInfo.tagIds)
                            "
                            v-model="searchInfo.tagIds"
                            style="width: 100%"
                          >
                            <el-option
                              v-for="(i, index) in TagList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="人才创建时间：" prop="remark">
                          <el-date-picker
                            style="width: 100%"
                            v-model="timeVal"
                            value-format="yyyy-MM-dd"
                            @change="getTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="人才更新时间："
                          prop="lastModifiedTime"
                        >
                          <el-date-picker
                            style="width: 100%"
                            v-model="lastModifiedTime"
                            value-format="yyyy-MM-dd"
                            @change="getlastModifiedTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="备注周期："
                          prop="commentPeriodEnum"
                          style="min-width: 280px"
                        >
                          <el-select
                            filterable
                            clearable
                            collapse-tags
                            @change="
                              submitForm(
                                '备注周期',
                                searchInfo.commentPeriodEnum
                              )
                            "
                            v-model="searchInfo.commentPeriodEnum"
                            style="width: 100%"
                          >
                            <el-option
                              v-for="(i, index) in remarkCycleList"
                              :key="index"
                              :label="i.name"
                              :value="i.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-col>
                </div>
              </el-collapse-transition>
            </el-form>
          </el-col>
        </el-row>
      </el-form>
      <div class="box-title" style="border-top: 0; border: none">
        <el-row :gutter="24">
          <el-col :span="24" class="table-top" style="padding-left: 27px">
            <el-pagination
              style="float: right"
              ref="pageNationOne"
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0 && !listLoading"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
            ></el-pagination>
          </el-col>
          <div class="clearfix" v-loading="listLoading">
            <template v-if="ListData.length">
              <el-col
                :span="24"
                v-for="(i, idx) in ListData"
                class="resumeCard"
                :key="String(idx) + i.id"
              >
                <div>
                  <el-card
                    shadow="hover"
                    class="shadowCard"
                    style="border-left: 0; border-right: 0"
                  >
                    <div class="personFoo" @click="goResumeFoo(i, idx)">
                      <div
                        style="display: inline-block; cursor: pointer"
                        class="cardHover"
                      >
                        <p
                          style="
                            display: inline-block;
                            margin-top: 0;
                            font-size: 16px;
                            font-weight: bold;
                          "
                          class="resumeName tabHref"
                          @mouseenter="showDetail(i, idx)"
                          @mouseleave="mouseoutWork && clearShowDetail()"
                        >
                          <a
                            class="tabHref"
                            :href="
                              '/resume/detailPage?resumeId=' +
                              i.id +
                              '&entry=列表右键新窗口'
                            "
                            @click.prevent="test"
                            v-html="i.name"
                          ></a>
                          <span v-if="i.name_pinyin">(</span>{{ i.name_pinyin
                          }}<span v-if="i.name_pinyin">)</span>
                        </p>
                        <span>
                          <el-tooltip
                            v-if="i.recommendTimes > 0"
                            class="item"
                            effect="dark"
                            :content="
                              '候选人被推荐了' + i.recommendTimes + '次'
                            "
                            placement="top-start"
                          >
                            <span
                              v-if="i.recommendTimes > 0"
                              class="badgeIcon"
                              style="background: #4690ff"
                              ><span class="num">{{
                                i.recommendTimes
                              }}</span></span
                            >
                          </el-tooltip>
                          <el-tooltip
                            v-if="i.isLocked"
                            class="item"
                            effect="dark"
                            :content="
                              '候选人在【' +
                              i.lockProjectName +
                              '】项目里已锁定'
                            "
                            placement="top-start"
                          >
                            <i
                              v-if="i.isLocked"
                              class="badgeIcon iconfont icon-yisuoding"
                            ></i>
                          </el-tooltip>
                          <el-tooltip
                            v-if="i.contactStatus == '联系中'"
                            class="item"
                            effect="dark"
                            :content="
                              i.contactRemark
                                ? i.contactRemark + '正在联系中'
                                : '正在联系中'
                            "
                            placement="top-start"
                          >
                            <i
                              v-if="i.contactStatus == '联系中'"
                              class="
                                badgeIcon
                                iconfont
                                icon-zhengzailianxizhong
                              "
                            ></i>
                          </el-tooltip>
                          <el-tooltip
                            v-if="i.secure"
                            class="item"
                            effect="dark"
                            content="人才保密中"
                            placement="top-start"
                          >
                            <i
                              v-if="i.secure"
                              class="badgeIcon iconfont icon-mi"
                              style="color: #da3737"
                            ></i>
                          </el-tooltip>
                          <el-tooltip
                            v-if="i.channel"
                            class="item"
                            effect="dark"
                            :content="'来源：' + i.channel"
                            placement="top-start"
                          >
                            <i
                              v-if="i.channel"
                              class="badgeIcon iconfont"
                              :class="{
                                'icon-liepin':
                                  i.channel == '猎聘猎头端' ||
                                  i.channel == '猎聘HR端',
                                'icon-luru': i.channel == '录入',
                                'icon-shangchuan':
                                  i.channel == '上传List简历' ||
                                  i.channel == '上传',
                                'icon-lingying':
                                  i.channel == '领英个人版' ||
                                  i.channel == '领英',
                                'icon-lagou': i.channel == '拉勾',
                                'icon-BOSSzhipin': i.channel == 'BOSS直聘',
                                'icon-qianchengwuyou': i.channel == '前程无忧',
                                'icon-zhilian':
                                  i.channel == '智联' || i.channel == '卓聘',
                                'icon-wuyoujingying': i.channel == '无忧精英',
                                'icon-maimai': i.channel == '脉脉',
                                'icon-qi':
                                  i.channel == '插件' ||
                                  i.channel == '合并' ||
                                  i.channel == '58同城',
                              }"
                            ></i>
                          </el-tooltip>
                        </span>
                        <span class="fontColor1 garyColor" v-if="i.sex">
                          . {{ i.sex }}</span
                        >
                        <span class="fontColor1 garyColor" v-if="i.age > 0">
                          . {{ i.age }}岁</span
                        >
                        <span class="fontColor1 garyColor" v-if="i.married">
                          . {{ i.married }}</span
                        >
                        <span class="fontColor1 garyColor" v-if="i.experience">
                          . {{ i.experience }}年工作经验</span
                        >
                        <template v-if="i.nowLocationName">
                          .
                          <span
                            class="fontColor1 garyColor"
                            v-html="i.nowLocationName"
                          ></span>
                        </template>
                      </div>
                      <span class="fontColor1 fr">
                        <span style="font-size: 12px; padding-right: 5px"
                          >{{ i.ownerName }}
                        </span>
                        <span
                          v-if="i.createdTime"
                          style="
                            padding-right: 5px;
                            border-right: 1px solid #ededed;
                            font-size: 12px;
                          "
                        >
                          {{ i.createdTime }}创建
                        </span>
                        <span
                          v-if="i.lastModifiedTime"
                          style="padding-left: 5px; font-size: 12px"
                        >
                          {{ i.lastModifiedTime }}更新</span
                        >
                      </span>
                    </div>
                    <div
                      class="fontColor1 garyColor clearfix"
                      :class="
                        i.employmentsList.length == 0
                          ? 'contactInformation'
                          : 'contactInformationBoreder'
                      "
                      v-if="
                        (i.mobile + i.email + i.wechatOrOther + i.virtual_phone)
                          .length > 0
                      "
                      style="padding: 10px 0 0; background: none"
                    >
                      <div class="fl">
                        <span style="margin-right: 25px" v-show="i.email">
                          <i
                            style="font-size: 12px; margin-right: 6px"
                            class="iconfont fontColor1 icon-rc_lb_yx garyColor"
                          ></i>
                          <span v-html="i.isShow ? i.email : '***********'">
                            {{ i.isShow ? i.email : "***********" }}</span
                          >
                        </span>
                        <span
                          style="margin-right: 25px"
                          v-show="i.wechatOrOther"
                        >
                          <i
                            style="font-size: 12px; margin-right: 6px"
                            class="iconfont fontColor1 icon-rc_lb_wx garyColor"
                          ></i>
                          <span
                            v-html="i.isShow ? i.wechatOrOther : '***********'"
                            >{{
                              i.isShow ? i.wechatOrOther : "***********"
                            }}</span
                          >
                        </span>
                        <span
                          style="margin-right: 25px"
                          v-show="i.mobile || i.virtual_phone"
                        >
                          <i
                            v-if="i.mobile || i.virtual_phone"
                            style="margin-right: 6px"
                            class="iconfont fontColor1 icon-rc_lb_sj garyColor"
                            @click="call(i.mobile)"
                          ></i>
                          <span
                            v-html="
                              i.isShow
                                ? i.mobile
                                  ? i.mobile
                                  : i.virtual_phone
                                : '***********'
                            "
                          ></span>
                          <el-tag
                            size="mini"
                            v-if="
                              i.mobile && i.virtual_phone
                                ? false
                                : i.virtual_phone
                                ? true
                                : false
                            "
                            >虚拟号</el-tag
                          >
                          <el-tag
                            class="button1"
                            size="mini"
                            v-if="i.mobileStatus !== null ? true : false"
                            :style="
                              i.mobileStatus == '正常'
                                ? 'background:#5189ff;'
                                : 'background:#333;'
                            "
                            style="
                              color: #fff;
                              border: 0;
                              height: 15px;
                              line-height: 17px;
                              padding: 0 3px;
                            "
                            >{{ i.mobileStatus }}</el-tag
                          >
                        </span>
                        <p
                          class="fontColor1 fr iconHover"
                          style="font-size: 12px; float: right"
                          @click="hideContact(i, idx)"
                        >
                          <i
                            class="iconfont fontColor1"
                            :class="i.isShow ? 'icon-rc_eye2' : 'icon-rc_eye1'"
                          ></i>
                          <span style="margin-left: 8px">{{
                            i.isShow ? "隐藏" : "显示"
                          }}</span>
                        </p>
                      </div>
                    </div>
                    <el-row class="flexContent">
                      <el-col :span="18">
                        <el-col
                          class="workStatus clearfix"
                          v-if="
                            i.employmentsList.length > 0 ||
                            i.educationsList.length > 0
                          "
                        >
                          <div @click="goResumeFoo(i, idx)">
                            <el-col :span="12" style="padding-left: 0">
                              <el-col
                                :span="24"
                                v-for="j in i.employmentsList"
                                :key="j.id"
                                style="margin-bottom: 2px; padding: 0"
                                v-if="j.company"
                              >
                                <div>
                                  <span
                                    @mouseover="postion(j)"
                                    :title="postionTitle"
                                    class="infoContent"
                                  >
                                    <fixed-length-span
                                      class="linkButton"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                      :width="160"
                                      :text="j.company"
                                    ></fixed-length-span>
                                    <template v-if="j.title">
                                      <fixed-length-span
                                        style="
                                          color: #898989 !important;
                                          font-size: 13px;
                                        "
                                        :text="'·' + j.title"
                                        :width="130"
                                      ></fixed-length-span>
                                    </template>
                                  </span>
                                  <span
                                    class="fontColor1 garyColor1"
                                    style="
                                      margin-left: 20px;
                                      width: 170px;
                                      font-size: 13px;
                                      line-height: 21px;
                                      color: #898989;
                                    "
                                    v-if="j.startDate || j.endDate || j.isSoFar"
                                    >{{ j.startDate }} 至
                                    {{ j.isSoFar ? "至今" : j.endDate }}</span
                                  >
                                </div>
                              </el-col>
                            </el-col>
                            <el-col :span="12" style="padding-left: 0">
                              <el-col
                                :span="24"
                                v-for="m in i.educationsList"
                                :key="m.id"
                                style="margin-bottom: 2px"
                              >
                                <div v-if="m.school">
                                  <span
                                    :title="
                                      m.school +
                                      (m.education ? ' · ' + m.education : '')
                                    "
                                    class="text-overflows infoContent"
                                  >
                                    <fixed-length-span
                                      class="linkButton"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                      :width="200"
                                      :text="m.school"
                                    ></fixed-length-span>
                                    <span
                                      v-if="m.education"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                    >
                                      · {{ m.education }}</span
                                    >
                                  </span>
                                  <span
                                    class="fontColor1"
                                    style="
                                      margin-left: 20px;
                                      width: 170px;
                                      font-size: 13px;
                                      line-height: 21px;
                                      color: #898989;
                                    "
                                    v-if="m.startDate || m.endDate || m.isSoFar"
                                    >{{ m.startDate }} 至
                                    {{ m.isSoFar ? "至今" : m.endDate }}</span
                                  >
                                </div>
                              </el-col>
                            </el-col>
                          </div>
                        </el-col>
                        <div style="margin-left: 20px">
                          <span
                            class="tag"
                            v-for="(x, index) in i.labels"
                            :key="index"
                            :style="
                              x.bordered
                                ? { 'border-color': x.color, color: x.color }
                                : {}
                            "
                            style="margin-bottom: 5px"
                            v-html="x.name"
                            >{{ x.name }}</span
                          >
                        </div>
                      </el-col>
                      <div
                        class="flexContent"
                        style="float: right"
                        :style="
                          (
                            i.mobile +
                            i.email +
                            i.wechatOrOther +
                            i.virtual_phone
                          ).length > 0
                            ? 'margin-top: -25px;'
                            : ''
                        "
                      >
                        <div class="radarContent" :id="i.id"></div>
                        <p
                          class="el-button el-button--primary"
                          style="
                            background: rgba(255, 140, 52, 0.1);
                            height: 30px;
                            width: 130px;
                            color: #ff7f57;
                            font-size: 13px;
                            padding: 8px 10px;
                            border-color: rgba(255, 140, 52, 0.1);
                          "
                        >
                          相似度：{{ i.similarPercent }}%
                        </p>
                      </div>
                    </el-row>
                    <!--              工作情况-->
                  </el-card>
                </div>
              </el-col>
            </template>
            <p v-else style="text-align: center; color: #6f6a6a">暂无数据……</p>
          </div>
          <div style="margin: 10px 0px 20px" class="clearfix">
            <el-col :span="24"
              ><el-pagination
                ref="pageNationOne"
                :hide-on-single-page="pagination.total > 10 ? false : true"
                v-if="pagination.total > 0 && !listLoading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="pagination.pageSize"
                layout="total,prev, pager, next,sizes"
                :total="pagination.total"
              ></el-pagination
            ></el-col>
          </div>
        </el-row>
      </div>
      <el-drawer
        :visible.sync="isShowDetail"
        append-to-body
        :modal="false"
        custom-class="drawerClass redrawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="handleClose"
      >
        <div
          slot="title"
          class="drawerTitle"
          style="display: flex; justify-content: space-between"
        >
          <p><i class="drawIcon iconfont icon-rc_bt"></i>人才</p>
          <div>
            <el-button size="mini" @click="goNewPage()"> 新窗口打开 </el-button>
            <el-button size="mini" @click="showleftContent()">
              {{ this.isshowleftContent ? "隐藏" : "显示" }}侧边栏
            </el-button>
          </div>
        </div>
        <div class="l_box" style="display: flex">
          <div class="l_content" v-if="isshowleftContent">
            <p class="l_c_tit">姓名</p>
            <div class="l_c_list">
              <p
                class="tabHref"
                :class="i.isChoice ? 'l_c_list_active' : ''"
                v-for="(i, idx) in ListData1"
                :key="String(idx) + i.id"
                @click="updata(i, idx)"
              >
                {{ i.name }}
              </p>
            </div>
          </div>
          <resumeDetails
            ref="resumeDetails"
            :style="
              isshowleftContent ? 'width: calc(100% - 210px);' : 'width:100%'
            "
            style="height: calc(100vh - 120px); overflow: auto"
            v-if="isShowDetail"
            source="相似人才"
            module="SIMILAR_CANDIDATE"
            :resumeDetailId="resumeId"
            :hover="hoverInfo"
            :searchKey="searchKeyVal"
            :repeatResume="repeatResume"
            :detailShowEntry="detailShowEntry"
            :searchInfo="detailSearchInfo"
            :searchObj="searchInfo"
            @getCallMobileInfo="getCallMobileInfo"
          ></resumeDetails>
        </div>
      </el-drawer>
      <el-dialog
        class="dialogFloatwindow"
        :visible.sync="mouseoverDialog"
        append-to-body
        :close-on-click-modal="true"
        :modal="false"
        custom-class="floatwindow"
        :before-close="resumeDigClose"
      >
        <div slot="title" class="drawerTitle">
          <i class="drawIcon iconfont icon-rc_bt"></i>人才
        </div>
        <resumeDetails
          v-if="mouseoverDialog"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :searchKey="searchKeyVal"
          :repeatResume="repeatResume"
          detailShowEntry="列表悬浮"
          module="SIMILAR_CANDIDATE"
          :searchInfo="detailSearchInfo"
          :searchObj="searchInfo"
          @getCallMobileInfo="getCallMobileInfo"
        ></resumeDetails>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import {
  cityTree, //城市树
  getCommonColumnList, //获取行业 城市列表
  getUserBaseInfos, //获取用户列表
  getLabelOptions, //获取标签列表
  resumeSimilarPage, //相似人才列表
} from "../../api/api";
import {
  statisticsResumeIndexPrecision, //记录人才精确搜索；用户仅使用精确搜索模块中的内容进行检索，检索一次上报一次
  statisticsClickSimilarDetailShow, //记录相似人才列表点击量
} from "../../api/buryingPoint";
import CityChoose from "../../components/cityChoose";
import * as data from "./../../libs/data.json";
import resumeDetails from "../../components/resumeDetail";
import FixedLengthSpan from "../../components/common/FixedLengthSpan";
import * as echarts from "echarts";
export default {
  components: {
    CityChoose,
    FixedLengthSpan,
    resumeDetails,
  },
  data() {
    const intNum = (rule, value, callback) => {
      // 校验数字
      let reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    return {
      searchInfo: {
        recordId: "",
        order: "",
        quickType: "所有人才",
        matchAll: true,
        majorSearch: false, //核心字段搜索
        searchKey: "",
        certificate: "", //证书
        channels: [], //渠道
        checkSchoolType: false, //是否按学校类型搜索
        comment: "", //候选人备注
        company: "", //公司名称
        contact: "", //联系方式
        educations: ["UnLimit"], //教育经历 [本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
        english: "", //语言能力
        favoriteId: "", //收藏夹Id
        firstDegrees: [], //第一学历
        forwardLocations: [], //期望城市
        forwardTitle: "", //期望职位
        forwardVocation: "", //期望职能
        forwardVocations: [], //期望行业
        functionIds: [], //职能
        highAimSalary: "", //期望最多月薪
        id: "", //简历编号
        isAllCompany: false, //是否全部公司
        isAllTitle: true, //是否全部职位
        is_tongzhao: false, //是否统招
        jobStatus: [], //求职状态
        joinProjectName: "", //加入项目名称
        lowAimSalary: "", //期望最少月薪
        major: "", //专业名称
        married: [], //婚姻状况
        maxAge: "", //最大年龄
        minAge: "", //最小年龄
        nowLocations: [], //现居住城市
        ownerUserIds: [], //人才拥有者
        projectName: "", //项目名称
        reasonOfLeaving: "", //离职原因
        tagIds: [], //标签
        resumeType: "", //候选人类型：1-候选人，2-code call
        school: "", //学校名称
        sex: "", //性别
        title: "", //职位名称
        type: "", //列表样式
        userName: "", //姓名
        vocationIds: [], //行业Id
        workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
        menuProperty: "快捷菜单",
        hits: 0,
        refresh: true,
      },
      rulesOfSearch: {
        id: [{ validator: intNum, trigger: "blur" }],
        minAge: [{ validator: intNum, trigger: "blur" }],
        maxAge: [{ validator: intNum, trigger: "blur" }],
        lowAimSalary: [{ validator: intNum, trigger: "blur" }],
        highAimSalary: [{ validator: intNum, trigger: "blur" }],
      },
      cascaderCityKey: 0,
      ColumnCityList: [], // 城市列表
      ColumnInList: [], //行业
      ColumnPosList: [], //职能
      cityProps: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: true,
      },
      isMoreCity: false,
      isShowName: false,
      isShowContact: false,
      isResouceShow: 0,
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      isShowTitle: false,
      isShowCompany: false,
      isMoreItemShow: false,
      isShowExpectPosition: false,
      isShowId: false,
      channelList: data.selectChannelList,
      userList: [], //用户列表
      TagList: [], //标签列表
      timeVal: "",
      lastModifiedTime: "",
      remarkCycleList: [
        { id: "HALF_YEAR", name: "近半年无备注" },
        { id: "ONE_YEAR", name: "近1年无备注" },
        { id: "TWO_YEAR", name: "近2年无备注" },
      ],
      searchUserList: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      listLoading: false,
      ListData: [],
      isShowDetail: false,
      mouseoverDialog: false,
      isshowleftContent: false,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      postionTitle: "",
      detailSearchInfo: {},
      mouseoutWork: true,
      time: false,
      resumeId: "",
      detailShowEntry: "",
    };
  },
  computed: {
    buttonStyle() {
      return (val) => {
        let buttonStyle = {};
        this.channelList.forEach((item) => {
          if (val == item.value) {
            buttonStyle = {
              color: `rgba(${item.color}, 1)`,
              backgroundColor: `rgba(${item.color}, .1)`,
              borderColor: `rgba(${item.color}, .2)`,
            };
          }
        });
        return buttonStyle;
      };
    },
  },
  created() {
    this.searchInfo.baseResumeId = parseInt(this.$route.query.resumeId);
    this.resumeId = parseInt(this.$route.query.resumeId);
    this.loadColumnInList("行业"); //获取行业列表
    this.loadColumnPosList("职能"); //获取职能列表
    this.loadColumnCityList("城市"); //获取城市列表
    this.loadTagList("人才"); //获取标签列表
    this.loadUserBase(); // 人才拥有者
    this.loadList();
  },
  methods: {
    isMoreCityClk() {
      this.cascaderCityKey = 0;
    },
    blurContact(e) {
      setTimeout(() => {
        this.isShowContact = false;
      }, 500);
    },
    blurUser(e) {
      setTimeout(() => {
        this.isShowName = false;
      }, 500);
    },
    blurCompany(e) {
      setTimeout(() => {
        this.isShowCompany = false;
      }, 500);
    },
    blurTitle(e) {
      setTimeout(() => {
        this.isShowTitle = false;
      }, 500);
    },
    blurId(e) {
      setTimeout(() => {
        this.isShowId = false;
      }, 500);
    },
    blurExpectPosition(e) {
      setTimeout(() => {
        this.isShowExpectPosition = false;
      }, 500);
    },
    //职能列表
    loadColumnPosList(type) {
      this.ColumnPosList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnPosList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    //行业列表
    loadColumnInList(type) {
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    //城市列表
    loadColumnCityList(type) {
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
          ++this.isResouceShow;
          // 获取默认城市北京、上海、广州、深圳、杭州、成都、武汉对应的id
          const defaultCity = [
            "北京",
            "上海",
            "广州",
            "深圳",
            "杭州",
            "成都",
            "武汉",
            "重庆",
          ];
          const defaultCityId = [[], [], [], [], [], [], [], []];
          for (var i = 0; i < defaultCity.length; i++) {
            this.ColumnCityList.forEach((item) => {
              if (item.name == defaultCity[i]) {
                defaultCityId[i].push(item.id);
              } else if (item.children) {
                item.children.forEach((it) => {
                  if (it.name == defaultCity[i]) {
                    defaultCityId[i].push(it.parentId);
                    defaultCityId[i].push(it.id);
                  }
                });
              }
            });
          }
          this.bjId = JSON.stringify(defaultCityId[0]);
          this.shId = JSON.stringify(defaultCityId[1]);
          this.gzId = JSON.stringify(defaultCityId[2]);
          this.szId = JSON.stringify(defaultCityId[3]);
          this.hzId = JSON.stringify(defaultCityId[4]);
          this.cdId = JSON.stringify(defaultCityId[5]);
          this.whId = JSON.stringify(defaultCityId[6]);
          this.cqId = JSON.stringify(defaultCityId[7]);
        }
      });
    },
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.searchUserList = res.result;
        }
      });
    },
    loadTagList(type) {
      //标签列表
      getLabelOptions().then((res) => {
        if (res.success) {
          this.TagList = res.result;
        }
      });
    },
    handleChangeNowLocations(e) {
      this.changArrOfNowLocations();
    },
    // 数组中内容转字符串
    changArrOfNowLocations() {
      this.radioNowLocations = this.searchInfo.nowLocations.map((item) => {
        return (item = JSON.stringify(item));
      });
      this.submitForm("现居住城市", this.radioNowLocations);
    },
    getTime(val) {
      this.timeVal = val;
      this.submitForm("人才创建时间", val[0] + "-" + val[1]);
    },
    getlastModifiedTime(val) {
      this.lastModifiedTime = val;
      this.submitForm("人才更新时间", val[0] + "-" + val[1]);
    },
    radioClick(type, id, name, index, create) {
      this.isCreate = create;
      if (type == 1 || type == 2) {
        this.searchInfo.resumeType =
          this.searchInfo.resumeType == type ? "" : type;
        this.submitForm("候选人类型", this.searchInfo.resumeType);
      } else if (type == "男" || type == "女") {
        this.searchInfo.sex = this.searchInfo.sex == type ? "" : type;
        this.submitForm("性别", this.searchInfo.sex);
      }
      this.loadList();
    },
    educationsChanged(e, type) {
      if (type == "checkSchoolType") {
        this.searchInfo.checkSchoolType = e;
      } else if (type == "is_tongzhao") {
        this.searchInfo.is_tongzhao = e;
      }
      this.submitForm("教育经历", e, undefined, type);
    },
    workExperienceChanged() {
      this.submitForm("工作年限", this.searchInfo.workExperiences);
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.loadList(1);
    },
    submitForm(type, val, entry, isEducationsType) {
      if (type && val) {
        if (type == "期望月薪") {
          val =
            this.searchInfo.lowAimSalary + "-" + this.searchInfo.highAimSalary;
        } else if (type == "年龄") {
          val = this.searchInfo.minAge + "-" + this.searchInfo.maxAge;
        }
        this.getStatisticsResumeIndexPrecision(
          type,
          val,
          entry,
          isEducationsType
        );
      }
      this.searchInfo.refresh = false;
      this.isCreate = true;
      this.radioGroup = "";
      this.$refs["searchInfo"].validate((valid) => {
        if (valid) {
          this.pagination.pageNo = 1;
          this.showFavorBtn = false;
          this.searchInfo.recordId = "";
          this.loadList();
        }
      });
    },
    //记录人才精确搜索；用户仅使用精确搜索模块中的内容进行检索，检索一次上报一次
    getStatisticsResumeIndexPrecision(type, val, entry, isEducationsType) {
      statisticsResumeIndexPrecision({
        searchKey:
          typeof val == "boolean"
            ? JSON.stringify(this.searchInfo.educations)
            : typeof val == "object"
            ? JSON.stringify(val)
            : val,
        type: type,
        entry: "相似人才",
        is_tongzhao: isEducationsType == "is_tongzhao" ? true : "",
        checkSchoolType: isEducationsType == "checkSchoolType" ? true : "",
      }).then();
    },
    loadList() {
      window.scrollTo(0, 0);
      //获取人才列表
      this.popVisible = true;
      this.$nextTick(() => {
        this.popVisible = false;
      });
      this.numbers = [];
      this.dialogResumeVisible = false;
      this.listLoading = true;
      this.dialogCusVisible = false;
      this.searchInfo.type = this.defaultType;
      this.searchInfo.page = {
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: false,
      };
      this.searchInfo.createStartTime = this.timeVal ? this.timeVal[0] : "";
      this.searchInfo.createEndTime = this.timeVal ? this.timeVal[1] : "";
      this.searchInfo.lastModifiedStartTime = this.lastModifiedTime
        ? this.lastModifiedTime[0]
        : "";
      this.searchInfo.lastModifiedEndTime = this.lastModifiedTime
        ? this.lastModifiedTime[1]
        : "";
      resumeSimilarPage(this.searchInfo).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
          for (let i = 0; i < this.ListData.length; i++) {
            let list = [];
            list = [
              this.ListData[i].baseSimilar,
              this.ListData[i].vocationSimilar,
              this.ListData[i].educationSimilar,
              this.ListData[i].employmentSimilar,
              this.ListData[i].skillSimilar,
            ];
            this.drawInRadarChart(
              this.ListData[i].id,
              list,
              this.ListData[i].similarPercent
            );
          }
        }
        this.listLoading = false;
      });
    },
    handleClose(done) {
      this.isshowleftContent = false;
      done();
    },
    // 显示隐藏侧边栏
    showleftContent() {
      this.ListData1 = this.ListData;
      this.ListData1.forEach((i) => {
        if (i.id == this.resumeId) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.isshowleftContent = !this.isshowleftContent;
    },
    //打开新窗口
    goNewPage() {
      let newpage = this.$router.resolve({
        path: "/resume/detailPage",
        query: { resumeId: this.resumeId },
      });
      window.open(newpage.href, "_blank");
    },
    getCallMobileInfo(params) {
      this.isShowDetail = false;
      this.loadList();
    },
    // 跳转到详情
    goResumeFoo(data, index) {
      statisticsClickSimilarDetailShow({
        superResumeId: this.resumeId,
        resumeId: data.id,
        resumeRank:
          (this.pagination.pageNo - 1) * this.pagination.pageSize + (index + 1),
        similarity: data.similarPercent,
      }).then();
      if (
        data.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !data.canViewSecure
      ) {
        this.$confirm("人才保密中，无法查看详情", "提示", {
          showConfirmButton: false,
          cancelButtonText: "关闭",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      this.isShowDetail = true;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = data.id;
      this.searchKeyVal = this.searchInfo.searchKey;
      this.hoverInfo.matchAll = this.searchInfo.matchAll;
      this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
      this.hoverInfo.hover = true;
      this.$store.dispatch("upDateResumeId", data.id);
    },
    //更新数据
    updata(data, index) {
      statisticsClickSimilarDetailShow({
        superResumeId: this.resumeId,
        resumeId: data.id,
        resumeRank: index + 1,
        similarity: data.similarPercent,
      }).then();
      this.ListData1.forEach((i) => {
        if (i.id == data.id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.detailShowEntry = "详情页侧边栏";
      this.isShowDetail = true;
      this.isShowDetail1 = false;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = data.id;
      this.searchKeyVal = this.searchInfo.searchKey;
      this.hoverInfo.matchAll = this.searchInfo.matchAll;
      this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
      this.hoverInfo.hover = true;
      this.$refs.resumeDetails.reflsh(data.id);
    },
    //选中
    chioceData(i) {
      this.choiceTypeName = i.typeName;
      let list = [];
      list = this.createTag.labelIds;
      let item = [i.id];
      if (!this.createTag.labelIds.includes(i.id)) {
        if (list.length === 0) {
          this.createTag.labelIds = [...item];
        } else {
          this.createTag.labelIds = [...list, ...item];
        }
      } else {
        this.$message.error("标签已选");
      }
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
    },
    resumeDigClose() {
      this.mouseoverDialog = false;
    },
    postion(val) {
      const div = document.createElement("div");
      div.innerHTML = val.company + (val.title ? " . " + val.title : "");
      this.postionTitle = div.innerText;
    },
    // 点击按钮是否显示联系方式
    hideContact(row, index) {
      if (!row.offerOrEntryPermission) {
        this.$message.error(
          "候选人处于【offer】或【入职】阶段，联系方式暂无法查看"
        );
      } else if (
        row.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !row.canViewSecure
      ) {
        this.$message.error("候选人处于【保密】中，联系方式暂无法查看");
      } else {
        this.ListData.splice(index, 1, {
          ...row,
          isShow: !row.isShow,
        });
      }
    },
    clearShowDetail() {
      if (this.timeOut) {
        clearInterval(this.timeOut);
        this.time = false;
      }
    },
    // 显示弹框
    showDetail(val, index) {
      if (
        val.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !val.canViewSecure
      ) {
        this.$confirm("人才保密中，无法查看详情", "提示", {
          showConfirmButton: false,
          cancelButtonText: "关闭",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      let that = this;
      this.rank =
        (this.pagination.pageNo - 1) * this.pagination.pageSize + (index + 1);
      if (this.mouseoverDialog) {
        this.mouseoverDialog = false;
      }
      this.hoverInfo.hover = false;
      this.timeOut = setTimeout(() => {
        this.resumeId = val.id;
        this.searchKeyVal = this.searchInfo.searchKey;
        this.hoverInfo.matchAll = this.searchInfo.matchAll;
        this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
        if (!that.time) {
          if (that.time !== undefined) {
            this.mouseoverDialog = true;
            this.time = false;
            this.$store.dispatch("upDateResumeId", val.id);
          }
        }
        if (document.querySelector("#CONTAINER-CC-TOOLBAR")) {
          document.querySelector("#CONTAINER-CC-TOOLBAR").style.zIndex = 10001;
        }
      }, 1500);
      this.repeatId = true;
    },
    //雷达图表
    drawInRadarChart(id, data, similarPercent) {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        var chartDom = document.getElementById(id);
        var myChart = echarts.init(chartDom);
        var option;
        option = {
          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            textStyle: {
              color: "#fff",
            },
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderWidth: "0",
            position: ["50%", "10%"],
            formatter: function (params) {
              let str =
                params.name +
                "：" +
                similarPercent +
                "% <hr style='border: 1px solid rgba(0,0,0,0.6);'>";
              let list = ["基本信息", "行业", "教育", "工作", "技能"];
              params.value.forEach((item, i) => {
                str += list[i] + "：" + item + "% </br>";
              });
              return str;
            },
          },
          radar: [
            {
              indicator: [
                { text: "基本信息", max: 100 },
                { text: "行业", max: 100 },
                { text: "教育", max: 100 },
                { text: "工作", max: 100 },
                { text: "技能", max: 100 },
              ],
              name: {
                textStyle: {
                  padding: -20,
                  color: "#898989",
                },
              },
              radius: 50,
            },
          ],
          series: [
            {
              type: "radar",
              symbol: "none",
              splitLine: {
                lineStyle: {
                  color: "#e5e5e5",
                },
              },
              areaStyle: {
                color: "rgba(138, 167, 232, 0.6)",
              },
              data: [
                {
                  value: data,
                  name: "相似度",
                },
              ],
            },
          ],
        };
        option && myChart.setOption(option);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../collapsed.less";
.resumeName {
  cursor: pointer;
}
.fontColor1 {
  color: #999;
}
.input-with-select.appandSelect {
  vertical-align: middle !important;
  .el-input-group__append {
    background-color: #fff;
    width: 65px;
    border-radius: 0;
  }
  .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-input-group__append:hover {
    border-color: #ededed !important;
  }
}
.input-with-select.appandSelect:hover {
  .el-input-group__append {
    border-color: #ededed !important;
  }
}
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
.shadowCard:hover {
  #triangle-topleft {
    display: inline-block;
  }
}

#triangle-topleft {
  display: none;
  width: 0;
  height: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  border-top: 30px solid #e7e7e7;
  border-right: 30px solid transparent;
}
#triangle-topleft::before {
  content: "\e6da";
  color: white;
  left: 2px;
  font-family: element-icons !important ;
  position: relative;
  top: -28px;
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input-group__prepend) {
    border-left: 1px solid #e60012;
    border-top: 1px solid #e60012;
    border-bottom: 1px solid #e60012;
    line-height: 33px;
    background-color: white;
  }
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
    border-right: none;
    border-left: none;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }

  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tableType {
  display: inline-block;
}
.comList {
  max-height: 400px;
  overflow: auto;
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    .comName {
      width: 80%;
      float: left;
      p {
        max-width: 60%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        height: 20px;
        width: 62px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        color: #efa14d;
        border: 1px solid #efa14d;
        border-radius: 3px;
        margin-left: 8px;
      }
    }
  }
}

.loacalStorage {
  border: 1px solid #ededed;
  width: calc(100% - 55px);
  z-index: 10;
  background: white;
  position: absolute;
}
.radioGroupBtn {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0 !important;
  .el-checkbox-button {
    box-shadow: none !important;
    .el-checkbox-button__inner {
      border-radius: 0;
      border: 0;
      padding: 7px 10px;
      color: #666;
      font-size: 14px !important;
    }
  }
  .el-checkbox-button.is-checked {
    .el-checkbox-button__inner {
      color: #fff;
    }
  }
}
.clearfix {
  .resumeCard:nth-child(1) {
    border-top: 1px solid #ebeef5;
  }
}
.similarResume {
  .linkButton {
    font-size: 13px;
    color: #999 !important;
  }
  .tag {
    margin-right: 5px;
  }
}
.iconHover:hover {
  cursor: pointer;
  color: #ff3f3f !important;
  .fontColor,
  i {
    color: #ff3f3f !important;
  }
}
.footBtns {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 100%;
  padding: 30px 15px;
  background: #fff;
}
.garyColor {
  color: #333;
  font-size: 13px;
}
.garyColor1 {
  color: #898989;
  font-size: 13px;
}
:deep(.el-loading-mask) {
  left: 15px;
  right: 15px;
}
.flexContent {
  display: flex;
}
.radarContent {
  width: 130px;
  height: 130px;
  margin: 0 35px;
}
</style>

<style scoped>
.searchInfo :deep(.el-select .el-tag) {
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}
.searchInfo :deep(.el-cascader__tags .el-tag) {
  overflow: hidden;
  max-width: 77px;
  text-overflow: ellipsis;
}
.searchInfo :deep(.el-popper) {
  width: 260px;
}
.searchInfo :deep(.el-input__suffix) {
  right: 10px;
}
.searchInfo .el-col-8 {
  padding-left: 0 !important;
}
.btnHover {
  color: rgb(51, 51, 51);
  font-size: 13px;
  cursor: pointer;
}
.btnHover:hover {
  color: rgb(41, 121, 255) !important;
  text-decoration: none;
  background: #f0f0f0;
}
.changeButtonStyle {
  padding: 6px;
  color: white;
  background: #ff3f3f;
  border: 1px #ff3f3f solid;
  margin-top: -1px;
  border-radius: 0 4px 4px 0;
}
.content-container :deep(.el-input .el-select .el-input) {
  width: 100px;
  padding-left: 8px;
}
.labelAppend :deep(.el-input-group__append) {
  border: none;
  padding: 0;
  width: 32px;
  background-color: white;
  position: absolute;
  right: 0px;
  top: 1px;
}
.labelAppend :deep(.el-input-group--append .el-input__inner) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.labelAppend1 :deep(.el-input-group--append .el-input__inner) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.labelAppend1 :deep(.el-input-group__append) {
  border: none;
  background-color: white;
  position: absolute;
  right: 9px;
  top: 2px;
}
.table-top {
  margin-bottom: 10px;
  margin-top: 10px;
}

.el-card {
  border-top: 0;
  border-radius: 0;
  overflow: inherit;
}
.el-card:hover {
  background: #f5f7fa;
  box-shadow: none;
}

.contactInformationBoreder {
  padding-bottom: 15px;
  margin-left: 20px;
}
.personFoo,
.contactInformation,
.workStatus {
  padding-left: 20px !important;
}
.workStatus {
  width: calc(100% - 22px);
  padding: 5px 0 10px !important;
  margin-left: 22px;
  margin-right: 22px;
}
.order {
  color: #606266;
  cursor: pointer;
}
.order:hover {
  color: #cf0010 !important;
}
.hoverColorChange {
  color: #606266;
  cursor: pointer;
  font-size: 13px;
}
.hoverColorChange:hover {
  color: #cf0010 !important;
}
.searchBox :deep(.el-checkbox-button__inner) {
  border: 0;
  background: #fafafa;
  color: #606266;
  box-shadow: none;
  font-size: 13px !important;
  padding: 7px 10px;
}
.searchBox :deep(.el-checkbox-button__inner:hover) {
  color: #e60012 !important;
}
.searchBox :deep(.el-form-item__label) {
  font-size: 13px !important;
}
.searchBox {
  padding: 0 !important;
}
.bodTop {
  border-top: 30px solid #ff3f3f !important;
}
.personFoo {
  padding: 15px 0 0 20px;
}
.searchRow :deep(.el-input .el-select .el-input) {
  width: 110px;
  padding-left: 10px;
}
.searchRow {
  padding-left: 0 !important;
}
.shadowCard :deep(.el-card__body) {
  padding: 0 20px 15px 0 !important;
  position: relative;
}
</style>

<style  lang="scss">
.dialogFloatwindow.el-dialog__wrapper {
  left: auto;
  box-shadow: -4px 0 15px -1px #dcdcdc;
}
.floatwindow {
  width: 1300px;
  margin-top: 0 !important;
  margin-right: 0;
  height: 100vh;
  @media (max-width: 1366px) {
    width: 1000px;
  }
  .el-dialog__body {
    overflow: scroll;
    padding: 0;
    @media (max-width: 1366px) {
    }
  }
  .drawerTitle {
    font-weight: bold;
    .drawIcon {
      color: #898989;
      font-weight: normal;
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
}
.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}
.l_box {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ededed;
    background: #ededed;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: #fff;
  }
}
.l_content {
  width: 200px;
  border-right: 1px #ececec solid;
  border-bottom: 1px #ececec solid;
  overflow: auto;
  height: calc(100vh - 120px);
  .l_c_tit {
    font-weight: bold;
    font-size: 15px;
    line-height: 30px;
    padding: 5px 20px;
    border-bottom: 1px #ececec solid;
  }
  .l_c_list {
    p {
      color: #606266;
      line-height: 25px;
      padding: 5px 20px;
    }
    p:hover {
      color: #526ecc;
      background: #ececec;
      text-decoration: none;
    }
  }
  .l_c_list_active {
    color: #526ecc !important;
    background: #ececec;
    text-decoration: none;
  }
}
.redrawerClass {
  .el-drawer__body {
    overflow: hidden;
  }
}
</style>

