import { render, staticRenderFns } from "./similarResume.vue?vue&type=template&id=8eab7c60&scoped=true&"
import script from "./similarResume.vue?vue&type=script&lang=js&"
export * from "./similarResume.vue?vue&type=script&lang=js&"
import style0 from "./similarResume.vue?vue&type=style&index=0&id=8eab7c60&prod&lang=scss&scoped=true&"
import style1 from "./similarResume.vue?vue&type=style&index=1&id=8eab7c60&prod&scoped=true&lang=css&"
import style2 from "./similarResume.vue?vue&type=style&index=2&id=8eab7c60&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8eab7c60",
  null
  
)

export default component.exports