<template>
  <el-checkbox-group
    class="radioGroupBtnPipe"
    size="mini"
    v-model="checked"
    @change="mainSearch()"
    text-color="#e60012"
    fill="#fafafa"
  >
    <el-checkbox-button
      v-if="defaultCityId.length > 0"
      v-for="(name, index) in defaultCity"
      :key="index"
      :label="JSON.stringify(defaultCityId[index])"
      >{{ name }}</el-checkbox-button
    >
  </el-checkbox-group>
</template>

<script>
import { cityTree } from "../api/api";

export default {
  name: "cityChoose",
  data() {
    return {
      ColumnCityList: [],
      defaultCityId: [
        [3],
        [847],
        [2203],
        [2226],
        [998],
        [2870],
        [1850],
        [2826],
      ],
      defaultCity: [
        "北京",
        "上海",
        "广州",
        "深圳",
        "杭州",
        "成都",
        "武汉",
        "重庆",
      ],
      checked: [],
      is_reset: false,
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    checked: {
      handler(v, ov) {
        if (!this.is_reset) {
          let res = this.getSearchKey();
          this.$emit("input", res);
        }
      },
      deep: true,
    },
    value: {
      handler(v, ov) {
        this.is_reset = true;

        this.checked = [];
        for (let l in this.value) {
          this.checked.push(JSON.stringify(this.value[l]));
        }
        let that = this;
        setTimeout(function () {
          that.is_reset = false;
        }, 200);
      },
      deep: true,
    },
  },
  mounted() {
    this.checked = [];
    for (let v in this.value) {
      this.checked.push(JSON.stringify(this.value[v]));
    }
    // this.loadColumnCityList()
  },
  methods: {
    getSearchKey() {
      let res = [];
      for (let c in this.checked) {
        res.push(JSON.parse(this.checked[c]));
      }
      return res;
    },
    mainSearch() {
      this.$emit("mainSearch");
    },
    loadColumnCityList() {
      //城市列表
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;

          for (var i = 0; i < this.defaultCity.length; i++) {
            this.ColumnCityList.forEach((item) => {
              let t = this.defaultCityId[i] || [];
              if (item.name == this.defaultCity[i]) {
                t.push(item.id);
              } else if (item.children) {
                item.children.forEach((it) => {
                  if (it.name == this.defaultCity[i]) {
                    t.push(it.parentId);
                    t.push(it.id);
                  }
                });
              }
              this.defaultCityId[i] = t;
            });
          }
          this.defaultCityId = JSON.parse(JSON.stringify(this.defaultCityId));
        }
      });
    },
  },
};
</script>
